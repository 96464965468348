import { api } from '@/api/api'
import { consoleLogSubscriber, log } from '@/log/log.ts'
import { useLocalStorage, useSessionStorage, useWindowSize } from '@vueuse/core'
import { useHead } from '@vueuse/head'
import { defineStore } from 'pinia'
import { computed, watch } from 'vue'

enum Environment {
    DEVELOPMENT,
    PRODUCTION
}

export const useGlobalStore = defineStore("globalStore", () => {
    const useTaskAutoRedirect = useSessionStorage("useTaskAutoRedirect", false)
    const useXStateViz = useSessionStorage("useXStateViz", false)
    const isDarkMode = useLocalStorage("isDarkMode", false)
    watch(isDarkMode, (isDarkMode) => {
        if (isDarkMode) {
            document.documentElement.classList.add("dark")
        } else {
            document.documentElement.classList.remove("dark")
        }
    }, {immediate: true})

    function toggleDarkMode(to?: boolean): void {
        if (to !== undefined) {
            isDarkMode.value = to
        } else {
            isDarkMode.value = !isDarkMode.value
        }
    }

    // could also use import.meta.env.PROD as a flag, but than only the developers would have the dev mode
    const showAPIConfig = computed(() => {
        const backend = api.baseURL.value
        if (backend != undefined) {
            return !backend.includes("api.curasoft.de")
        } else {
            return true
        }
    })
    const environment = window.location.hostname.includes("stage.curatest.de") || window.location.hostname.includes("curasoft.de") ? Environment.PRODUCTION : Environment.DEVELOPMENT
    const isProdMode = computed(() => environment === Environment.PRODUCTION)
    const isDevMode = computed(() => environment === Environment.DEVELOPMENT)
    watch(isDevMode, (isDevMode) => {
        if (isDevMode) {
            useHead({
                title: "CSI (Dev mode)",
                meta: [{name: "description", content: "CSI"}],
            })
        } else {
            useHead({
                title: "CSI",
                meta: [{name: "description", content: "CSI"}],
            })
        }
    }, {immediate: true})


    const {width: windowWidth} = useWindowSize({})
    const isDesktop = computed(() => windowWidth.value >= 960)

    const doConsoleLogErrors = useLocalStorage('doConsoleLogErros', false)
    watch(doConsoleLogErrors, (yes) => {
        if (yes) {
            log.subscribe(consoleLogSubscriber)
        } else {
            log.unsubscribe(consoleLogSubscriber)
        }
    }, {immediate: true})

    return {
        isDarkMode,
        isDesktop,
        isDevMode,
        isProdMode,
        showAPIConfig,
        toggleDarkMode,
        useTaskAutoRedirect,
        useXStateViz,
        doConsoleLogErrors,
    }
})
