import UserModel from "@/models/api/User.ts"
import { defineStore } from "pinia"
import { ref } from "vue"
import { fetchPortrait } from "@/api/api/ProfilePicture.ts"
import { fetchUser } from "@/api/webapi/users.ts"
import { DateTime, Duration } from "luxon"

export type User = UserModel & {
    createdAt: DateTime,
    updatedAt: DateTime,
    cachedAt: DateTime,
    image: {
        small: string | null,
        medium: string | null,
        large: string | null,
    },
}

const CACHE_DURATION = Duration.fromObject({seconds: 10})
export const useUserInfoStore = defineStore('userInfo', () => {

    const users = ref(new Map<number, User>)
    const queue: { [id: number]: Promise<User | null> } = {}

    /**
     * @throws APIError
     * */
    async function get(userId: number): Promise<User | null> {
        if (userId <= 0) return null
        let user: User | undefined | null = users.value.get(userId)
        if (user &&
            (user.cachedAt.diffNow() > CACHE_DURATION)) {
            user = undefined
        }
        if (user) {
            return Promise.resolve(user)
        } else {
            if (queue[userId] != undefined) {
                return queue[userId]
            } else {
                const promise = updateFromRemote(userId)
                    .finally(() => {
                        delete queue[userId]
                    })
                queue[userId] = promise
                return promise
            }
        }
    }

    async function updateFromRemote(userId: number): Promise<User | null> {
        return fetchUser(userId)
            .then((userModel) => {
                if (userModel == undefined) return null
                const user = createUserFromModel(userModel)
                add(user)
                loadImages(user.id)
                return user
            }).catch(() => {
                return null
            }) // ignore as not found
    }

    function createUserFromModel(userModel: UserModel): User {
        return {
            ...userModel,
            image: {small: null, medium: null, large: null},
            cachedAt: DateTime.now(),
            createdAt: DateTime.fromISO(userModel.created_at),
            updatedAt: DateTime.fromISO(userModel.updated_at),
        }
    }

    async function loadImages(userId: number): Promise<void> {
        const user = users.value.get(userId)
        if (user == undefined || user.caption == null) return

        await fetchPortrait(user.caption, {size: "small"})
            .then((portrait) => user.image.small = portrait)
            .then(() => {
                if (user == undefined || user.caption == null) return
                return Promise.all([
                    fetchPortrait(user.caption, {size: "medium"})
                        .then((portrait) => user.image.medium = portrait)
                        .catch(() => user.image.medium = null),
                    fetchPortrait(user.caption, {size: "large"})
                        .then((portrait) => user.image.large = portrait)
                        .catch(() => user.image.large = null),
                ])
            })
            .catch(() => user.image.small = null)

        users.value.set(userId, user)
    }

    function update(userId: number, properties: object): void {
        const user: undefined | User = users.value.get(userId)
        if (user == undefined) return
        for (const [key, value] of Object.entries(properties)) {
            user[key] = value
        }

        users.value.set(userId, user)
    }

    function add(user: User) {
        users.value.set(user.id, user)
    }

    return {
        get,
        update,
        add,
        loadImages,
        users,
    }


})
