import { api } from '@/api/api'
import type { OnError, OnSuccess } from '@/api/types'
import { getResponseStatusCode, notifyErrorNotAuthRelated } from '@/api/utils'
import { check as checkRequest, login as loginRequest } from '@/api/webapi/Auth'
import { LoggableError } from '@/log/log.ts'
import type AuthCheckModel from '@/models/AuthCheck'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, readonly, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export enum LoginState {
    loggedOut,
    pending,
    loggedIn
}

export const useAuthStore = defineStore('authStore',
    () => {
        const router = useRouter()
        const currentUser = ref<AuthCheckModel | null>(null)
        const currentUserReadOnly = readonly(currentUser)

        const state = ref(LoginState.loggedOut)

        const isLoggedIn = computed(() => state.value === LoginState.loggedIn)
        const isLoginLoading = computed(() => state.value === LoginState.pending)
        const isLoggedOut = computed(() => state.value === LoginState.loggedOut)

        function setLoggedOut() {
            state.value = LoginState.loggedOut
            token.value = null
            currentUser.value = null
            api.unauthorize()
        }

        function setLoginPending() {
            state.value = LoginState.pending
            currentUser.value = null
        }

        function setLoggedIn(user: AuthCheckModel) {
            state.value = LoginState.loggedIn
            currentUser.value = user
        }

        /** Go to login page on logout */
        watch(state, (is, was) => {
            if (was === LoginState.loggedIn && is === LoginState.loggedOut) {
                router.push('/login')
            }
        })

        const token = useLocalStorage<null | string>('token', null)
        const tokenExpirationDate = computed<Date | null>(() => {
            let date: null | Date = null
            if (token.value != null) {
                const payload = parseJWTPayload(token.value)
                if ('exp' in payload) {
                    date = new Date((payload.exp as number) * 1000) // seconds to milliseconds
                }
            }
            return date
        })

        /** reject outdated tokens */
        watch(tokenExpirationDate, checkTokenDate, {immediate: true})

        function checkTokenDate(tokenExpirationDate: Date | null) {
            if (tokenExpirationDate != null && tokenExpirationDate.getTime() < Date.now()) {
                setLoggedOut()
            }
            //Todo: try to get a new token if time is between 0-4am
        }

        function parseJWTPayload(token: string): object {
            const parts = token.split('.')
            if (parts.length !== 3) {
                //TODO log.error('JWT Parse Error: Token has unexpected shape <' + token + '>')
                return {}
            }
            try {
                return JSON.parse(window.atob(parts[1]))
            } catch (e) {
                // log.error('JWT Parse Error:', e, '; with token <' + token + '>')
                return {}
            }
        }

        function setToken(_token: string): void {
            token.value = _token
            api.authorize(_token)
        }

        /** Returns true if logged in on finish.*/
        async function updateSession(): Promise<boolean | void> {
            checkTokenDate(tokenExpirationDate.value)
            return checkRequest()
                .then((currentUser) => {
                    setLoggedIn(currentUser)
                    return true
                })
                .catch((error) => {
                    const statusCode = getResponseStatusCode(error)
                    if (statusCode === 401) {
                        setLoggedOut()
                        return false
                    } else {
                        throw new LoggableError([{
                            contextType: 'message',
                            message: 'trying to update session status (check if login valid).',
                        }], error)
                    }
                })
        }

        function login(username: string,
                       password: string,
                       options: {
                           onSuccess?: OnSuccess,
                           onError?: OnError,
                       } = {}) {
            setLoginPending()
            return loginRequest(username, password)
                .then((fetchedToken) => {
                    token.value = fetchedToken
                    api.authorize(fetchedToken)
                    return checkRequest()
                })
                .then((currentUser) => {
                    setLoggedIn(currentUser)
                    if (options.onSuccess) options.onSuccess()
                })
                .catch((error) => {
                    if (options.onError) options.onError(error)
                    setLoggedOut()
                })
        }

        /** updates user rights once per minute */
        function runPeriodicUpdates() {
            setTimeout(() => {
                if (isLoggedIn.value) {
                    updateSession()
                        .catch(notifyErrorNotAuthRelated)
                }
                runPeriodicUpdates()
            }, 60_000)
        }

        // Initialize Store
        if (import.meta.env.PROD) {
            runPeriodicUpdates()
        }

        return {
            logout: setLoggedOut,
            login,
            setToken,
            updateSession,
            isLoggedIn,
            isLoggedOut,
            isLoginLoading,
            currentUser: currentUserReadOnly,
        }
    })

export function useUserRight(right: string) {
    const authStore = useAuthStore()
    const hasRight = ref(false)
    authStore.$subscribe((_mutation, state) => {
        hasRight.value = (state.currentUser?.isAdmin || state.currentUser?.userRights.includes(right)) ?? false
    }, {immediate: true, deep: true})

    return {hasRight}
}
