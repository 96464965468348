import type { RouteLocationRaw } from "vue-router"
import type { InjectionKey, Ref } from "vue"
import { computed, inject, isRef, onMounted, onUnmounted, provide, ref, unref, watch } from "vue"

const useBreadcrumbKey = Symbol() as InjectionKey<{
    set: (crumbs: Breadcrumb[]) => void,
    reset: () => void
}>

/**
 * Use Provide/Inject to communicate the breadcrumbs or page heading
 * to the page component. E.g.  MyPage.vue uses provideBreadcrumbs and
 * gives them to the Layout.vue component. Every child of MyPage.vue can
 * now update/set these breadcrumbs with `usePageBreadcrumbs()`,
 * regardless how deeply nested the routing gets.
 */


export interface Breadcrumb {
    label: string,
    route?: RouteLocationRaw,
}

export function providePageBreadcrumb(crumbs: Breadcrumb[] = []): { breadcrumbs: Ref<Breadcrumb[]> } {
    const breadcrumbs = ref<Breadcrumb[]>(crumbs ? crumbs : [])

    function setBreadcrumb(crumbs: Breadcrumb[]) {
        breadcrumbs.value = crumbs
    }

    function resetBreadcrumbs() {
        breadcrumbs.value = crumbs
    }

    provide(useBreadcrumbKey, {
        set: setBreadcrumb,
        reset: resetBreadcrumbs
    })

    return {
        breadcrumbs
    }
}

export function usePageBreadcrumb(crumbs: Breadcrumb[] | Ref<Breadcrumb[]>) {
    const updateBreadcrumb = inject(useBreadcrumbKey)

    if (isRef(crumbs)) {
        watch(crumbs, (crumbs) => {
            updateBreadcrumb?.set(unref(crumbs))
        })
    }

    onMounted(() => {
        updateBreadcrumb?.set(unref(crumbs))
    })

    onUnmounted(() => {
        updateBreadcrumb?.reset()
    })
}


export const useHeadingKey = Symbol() as InjectionKey<{
    add: (heading: string) => void,
    remove: () => void
}>

export function providePageHeading(defaultHeading = ""): { heading: Ref<string> } {
    const headingStack = ref([defaultHeading])
    const heading = computed(() => headingStack.value.length > 0 ? headingStack.value[headingStack.value.length - 1] : "")

    function addHeading(_heading: string) {
        headingStack.value.push(_heading)
    }

    function removeHeading() {
        headingStack.value.pop()
    }

    provide(useHeadingKey, {
        add: addHeading,
        remove: removeHeading
    })

    return {
        heading
    }
}

export function usePageHeading(heading: string | Ref<string>) {
    const updateHeading = inject(useHeadingKey)

    if (isRef(heading)) {
        watch(heading, (_heading) => {
            updateHeading?.remove()
            updateHeading?.add(_heading)
        })
    }

    onMounted(() => {
        updateHeading?.add(unref(heading))
    })

    onUnmounted(() => {
        updateHeading?.remove()
    })
}


