import { api } from '@/api/api.ts'
import type { OnError, OnSuccess } from '@/api/types.ts'

export function fetchPortrait(username: string, options: {
    size: 'small' | 'medium' | 'large',
    onSuccess?: OnSuccess,
    onError?: OnError
} = {size: 'small'}): Promise<string | null> {
    if (username === '' || username.toLowerCase() === 'test' || username.length <= 2) return Promise.resolve(null)
    const endpoint = options.size === 'small' ? '/small' : options.size === 'medium' ? '/medium' : ''
    return api.fetchParsed(`/odoo/portrait/${username}` + endpoint)
}
