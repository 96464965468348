<script lang="ts" setup>
import { api } from "@/api/api.ts"
import CuraSoftLogo from "@/components/shared/CuraSoftLogo.vue"
import Tooltip from "@/components/shared/Tooltip.vue"
import UserMenu from "@/layouts/MainNav/UserMenu.vue"
import { useAuthStore, useUserRight } from "@/store/AuthStore.ts"
import { useGlobalStore } from "@/store/globals.ts"
import { type User, useUserInfoStore } from "@/store/userInfo.ts"
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue"
import { computed, ref, watch } from "vue"

const props = defineProps<{
    heading: string
}>()

const APP_VERSION = import.meta.env.VITE_APP_VERSION

const {hasRight: hasRightUsers} = useUserRight("users")
const {hasRight: hasRightCustomer} = useUserRight("customer")
const {hasRight: hasRightVersion} = useUserRight("version")
const {hasRight: hasRightQM} = useUserRight("qm")

const authStore = useAuthStore()
const globals = useGlobalStore()
const isMobileModalOpen = ref(false)

function closeModal() {
    isMobileModalOpen.value = false
}

const firstModalLinkElement = ref(null)

const userStore = useUserInfoStore()
const currentUser = ref<User | null>(null)
const caption = computed(() => {
    return currentUser.value?.caption !== '' ? currentUser.value?.caption : currentUser.value?.name
})

watch([() => authStore.currentUser?.userId,
    () => userStore.users], async ([id]) => {
    if (id != null && !Number.isNaN(id)) {
        currentUser.value = await userStore.get(id) ?? null
    }
}, {deep: true, immediate: true})

const hasImage = computed(() => currentUser.value?.image.small != null)

</script>

<template>
    <Transition
        appear-active-class="motion-preset-slide-down"
        enter-active-class="motion-preset-slide-down"
        leave-active-class="absolute top-0 motion-delay-100 motion-translate-y-out-[-100%] motion-opacity-out-0"
        appear
    >
        <div
            v-if="authStore.isLoggedIn"
            class="flex w-full items-center justify-between border-gray-700 bg-cs-900
      p-4 text-white desktop:border-b-[1px] desktop:px-11"
        >
            <div
                class="relative flex w-full items-center justify-between"
            >
                <div
                    v-if="globals.isDesktop"
                    class="flex flex-1 items-center justify-between gap-8 sm:items-stretch sm:justify-start"
                >
                    <div class="flex shrink-0 items-center">
                        <div class="h-12 w-auto">
                            <CuraSoftLogo color="white"/>
                        </div>
                    </div>
                    <div class="flex items-center gap-4 font-display">
                        <!-- Dashboard -->
                        <RouterLink
                            id="dashboard"
                            :class="{'bg-cs-900-darker text-white dark:bg-cs-900': $route.path.includes('/dashboard')}"
                            class="rounded-lg px-5 py-2 text-lg text-white hover:bg-cs-900-lighter hover:text-white"
                            to="/dashboard"
                        >
                            <span>
                                Dashboard
                            </span>
                        </RouterLink>

                        <!-- Kunden -->
                        <RouterLink
                            v-if="hasRightCustomer"
                            id="kunden"
                            :class="{'bg-cs-900-darker text-white dark:bg-cs-950': $route.path.includes('/kunden')}"
                            :to="{name: 'kunden'}"
                            class="rounded-lg px-5 py-2 text-lg text-white hover:bg-cs-900-lighter hover:text-white"
                        >
                            Kunden
                        </RouterLink>

                        <!-- Entwicklung -->
                        <RouterLink
                            v-if="hasRightVersion || hasRightQM"
                            id="entwicklung"
                            :class="{'bg-cs-900-darker text-white dark:bg-cs-950': $route.path.includes('/entwicklung')}"
                            class="rounded-lg px-5 py-2 text-lg text-white hover:bg-cs-900-lighter hover:text-white"
                            to="/entwicklung/projekte"
                        >
                            Entwicklung
                        </RouterLink>

                        <!-- Administration -->
                        <RouterLink
                            v-if="hasRightUsers"
                            id="administration"
                            :class="{'bg-cs-900-darker text-white dark:bg-cs-950': $route.path.includes('/administration')}"
                            class="rounded-lg px-5 py-2 text-lg text-white hover:bg-cs-900-lighter hover:text-white"
                            to="/administration"
                        >
                            Administration
                        </RouterLink>
                    </div>
                </div>

                <!-- Profile dropdown  Start-->
                <div
                    class="flex items-center gap-4"
                >
                    <!-- Dark Mode Button -->

                    <Tooltip
                        v-if="globals.isDesktop"
                        :tooltip-text="globals.isDarkMode ? 'wechsel zu hell' : 'wechsel zu dunkel'"
                        position="left"
                    >
                        <!--                            :class="{'align-bottom': hasImage, 'align-baseline': !hasImage}"-->
                        <IconFA
                            :icon="globals.isDarkMode ? 'sun': 'moon'"
                            class="aspect-square cursor-pointer"
                            @click="globals.toggleDarkMode()"
                        />
                    </Tooltip>

                    <!-- Username -->
                    <p
                        v-if="globals.isDesktop"
                        id="username"
                        :class="{'self-center': hasImage}"
                        class="mr-2 cursor-default self-center text-lg text-white"
                    >
                        {{ caption }}
                    </p>
                    <UserMenu :popup-position="globals.isDesktop ? 'right': 'left'"/>
                </div>
                <!-- Profile dropdown End-->
                <template v-if="!globals.isDesktop">
                    <Transition
                        mode="out-in"
                        name="slide-down-up"
                    >
                        <span
                            v-if="props.heading.length > 0"
                            class="shrink cursor-default text-ellipsis px-8 font-display text-3xl"
                            v-html="props.heading"
                        ></span>
                        <CuraSoftLogo
                            v-else
                            color="white"
                            class="h-[1em] align-baseline text-3xl"
                        />
                    </Transition>
                </template>
                <button
                    v-if="!globals.isDesktop"
                    @click="isMobileModalOpen = true"
                >
                    <IconFA
                        class="text-2xl"
                        icon="bars"
                    />
                </button>
            </div>
        </div>
    </Transition>
    <TransitionRoot
        :show="isMobileModalOpen"
    >
        <Dialog
            :initial-focus="firstModalLinkElement"
            class="fixed top-0 z-40 h-dvh w-dvw bg-gray-100 dark:bg-cs-950"
            @close="() => closeModal()"
        >
            <TransitionChild
                enter="duration-200 ease-out"
                enter-from="opacity-0 translate-x-8"
                enter-to="opacity-100 translate-x-0"
                leave="duration-200 ease-out"
                leave-from="opacity-100 translate-x-0"
                leave-to="opacity-0 translate-x-8"
            >
                <DialogPanel
                    as="div"
                    class="fixed top-0 z-40 grid h-dvh w-dvw
         grid-rows-[min-content_1fr_min-content] gap-4 bg-gray-100 text-xl text-black
        transition-colors dark:bg-cs-950 dark:text-white"
                >
                    <div class="relative flex justify-between pb-1 text-2xl">
                        <button
                            class="h-full pl-2 pt-2"
                            tabindex="-1"
                            @click="globals.toggleDarkMode()"
                        >
                            <Transition
                                :duration="100"
                                mode="out-in"
                                name="slide-down"
                            >
                                <IconFA
                                    v-if="globals.isDarkMode"
                                    class="aspect-square p-2 align-middle"
                                    icon="sun"
                                    style="filter: drop-shadow(1px 0px 4px rgb(255,224,156));"
                                    tabindex="0"
                                />
                                <IconFA
                                    v-else
                                    class="aspect-square p-2 align-middle"
                                    icon="moon"
                                    tabindex="0"
                                />
                            </Transition>
                        </button>
                        <div class="pointer-events-none absolute inset-x-0 top-0 flex justify-center">
                            <CuraSoftLogo
                                :color="globals.isDarkMode ? 'white' : 'black'"
                                class="mt-1 aspect-square h-12 opacity-10"
                            />
                        </div>
                        <button
                            class="h-full pr-2 pt-2"
                            tabindex="-1"
                            @click="closeModal()"
                            @keydown.enter="closeModal()"
                        >
                            <IconFA
                                class="aspect-square p-2 align-middle text-3xl"
                                icon="xmark"
                                tabindex="0"
                            />
                        </button>
                    </div>
                    <div
                        class="flex h-full flex-col self-end overflow-y-auto p-4 font-display text-3xl"
                    >
                        <RouterLink
                            ref="firstModalLinkElement"
                            class="border-t py-4 dark:border-gray-800"
                            to="/dashboard"
                            @click="closeModal()"
                        >
                            Dashboard
                        </RouterLink>
                        <RouterLink
                            v-if="hasRightCustomer"
                            :to="{name: 'kunden'}"
                            class="border-t py-4 dark:border-gray-800"
                            @click="closeModal()"
                        >
                            Kunden
                        </RouterLink>
                        <RouterLink
                            v-if="hasRightVersion || hasRightQM"
                            class="border-t py-4 dark:border-gray-800"
                            to="/entwicklung/projekte"
                            @click="closeModal()"
                        >
                            Entwicklung
                        </RouterLink>
                        <RouterLink
                            v-if="hasRightUsers"
                            class="border-t py-4 dark:border-gray-800"
                            to="/administration"
                            @click="closeModal()"
                        >
                            Administration
                        </RouterLink>
                        <div class="border-t py-4 dark:border-gray-800"></div>
                    </div>
                    <div class="space-y-4">
                        <div
                            class="flex items-baseline justify-between px-4 font-display text-gray-600 dark:text-gray-400"
                        >
                            <a
                                href="https://www.curasoft.de/impressum/"
                                @click="closeModal()"
                            >
                                Impressum
                            </a>
                            <a
                                href="https://www.curasoft.de/datenschutzerklaerung/"
                                @click="closeModal()"
                            >
                                Datenschutz
                            </a>
                        </div>
                        <div class="flex items-baseline justify-between text-gray-400 dark:text-gray-600">
                            <RouterLink
                                class="h-full pb-2 pl-2"
                                tabindex="-1"
                                to="/config"
                                @click="closeModal()"
                            >
                                <IconFA
                                    class="aspect-square p-2 align-middle"
                                    icon="gear"
                                    tabindex="0"
                                />
                            </RouterLink>
                            <p class="h-full pb-2 pr-4 font-mono text-sm ">
                                <span v-if="globals.showAPIConfig">{{ api.baseURL.value }} - </span>{{
                                    APP_VERSION
                                }}
                            </p>
                        </div>
                    </div>
                </DialogPanel>
            </TransitionChild>
        </Dialog>
    </TransitionRoot>
</template>
