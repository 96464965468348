import { api } from '@/api/api'
import type Right from '@/models/api/Right'
import type User from '@/models/api/User'
import type newUser from '@/models/newUser'
import type UserRight from '@/models/UserRight'

export function fetchUserRights(
    userId: number,
): Promise<UserRight> {
    return api.fetchParsed(`/webapi/users/rights/${userId}`)
}

export function updateUserRights(
    userId: number,
    newUserRights: Right[],
): Promise<void> {
    return api.fetchParsed(`/webapi/users/rights/${userId}`, {
        requestOptions: {
            method: "POST",
            body: JSON.stringify(newUserRights)
        }
    })
}

export interface PasswordChangeResponse {
    valid: boolean,
    messages: string[]
}

export function changePassword(userId: number,
                               userPassword: string,
                               userPasswordVerify: string,
): Promise<PasswordChangeResponse> {
    return api.fetchParsed(`/webapi/users/password/${userId}`, {
        urlParameters: {
            password: userPassword,
            password_verify: userPasswordVerify
        },
        requestOptions: {
            method: "POST"
        }
    })
}

export function createUser(user: newUser | User) {
    return api.fetchParsed(`/webapi/users/data`, {
        requestOptions: {
            method: "POST",
            body: JSON.stringify(user)
        }
    })
}

export function fetchUser(userId: number,
): Promise<User> {
    return api.fetchParsed(`/webapi/users/data/${userId}`)
}

export function updateUser(user: User) {
    return api.fetchParsed(`/webapi/users/data/${user.id}`, {
        requestOptions: {
            method: "POST",
            body: JSON.stringify(user)
        }
    })
}

export function deleteUser(userId: number) {
    return api.fetchParsed(`/webapi/users/${userId}`, {
        requestOptions: {
            method: "DELETE",
        }
    })
}

export interface checkResponse {
    valid: boolean
    messages: string[]
}

export function checkName(name: string,
                          userId: number,
): Promise<checkResponse> {
    return api.fetchParsed(`/webapi/users/check/name`, {
        requestOptions: {
            method: "POST",
            body: JSON.stringify({
                name: name,
                userId: userId
            })
        }
    })
}

export function checkEmail(email: string,
                           userId: number,
): Promise<checkResponse> {
    return api.fetchParsed(`/webapi/users/check/email`, {
        requestOptions: {
            method: "POST",
            body: JSON.stringify({
                email: email,
                userId: userId
            })
        }
    })
}


/**
 * TODO:
 * DELETE /webapi/users/data/{userId}
 * POST /webapi/users/data
 * GET /webapi/users/rights/{userId}
 * POST /webapi/users/rights/{userId}
 * POST /webapi/users/check/password
 */
