<script lang="ts" setup>

import { ref, watch } from 'vue'

const {color = 'colored'} = defineProps<{
    color?: 'black' | 'white' | 'colored'
}>()

const CS_RED = "#ff502c"
const CS_BLUE = "#008fff"

const leftColorFill = ref(CS_RED)
const rightColorFill = ref(CS_BLUE)

watch(() => color, (color) => {
    if (color === 'colored') {
        leftColorFill.value = CS_RED
        rightColorFill.value = CS_BLUE
    } else if (color === 'white') {
        leftColorFill.value = "#ffffff"
        rightColorFill.value = "#ffffff"
    } else {
        leftColorFill.value = "#000000"
        rightColorFill.value = "#000000"
    }
}, {immediate: true})

</script>

<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 1102 1082"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
    ><g>
        <path
            d="M582.87,766.663l0,170.98c0,24.472 19.838,44.31 44.31,44.31l426.314,-0c24.472,-0 44.31,-19.838 44.31,-44.31l0,-661.786c0,-24.472 -19.838,-44.31 -44.31,-44.311l-426.314,0c-24.472,0.001 -44.31,19.839 -44.31,44.311l0.006,169.24l0,239.024l11.643,-0.369c16.873,0 62.547,-7.855 93.675,-16c79.711,-20.655 168.732,-66.329 237.097,-121.603c34.328,-27.637 36.946,-29.092 48.583,-27.055c16.291,3.2 25.31,18.909 19.491,34.037c-3.782,10.473 -43.055,51.201 -73.602,76.802c-90.474,76.22 -203.641,125.094 -310.698,134.403l-26.195,2.327Z"
            :style="{fill: leftColorFill}"
        />
        <path
            d="M518.595,684.286l0.003,-639.976c-0,-24.472 -19.838,-44.31 -44.31,-44.31l-426.314,0c-24.472,0 -44.31,19.838 -44.31,44.31l-0,993c-0,24.472 19.838,44.31 44.31,44.31l426.314,0c24.472,0 44.31,-19.838 44.31,-44.31l-0,-270.481l-26.195,-2.327c-107.057,-9.309 -220.224,-58.183 -310.699,-134.403c-30.546,-25.601 -69.819,-66.329 -73.601,-76.802c-5.819,-15.128 3.2,-30.837 19.491,-34.037c11.637,-2.037 14.255,-0.582 48.583,27.055c68.365,55.274 157.386,100.948 237.097,121.603c31.128,8.145 76.802,16 93.675,16l11.646,0.368Z"
            :style="{fill: rightColorFill}"
        />
        </g>
    </svg>
</template>
