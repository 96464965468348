<script lang="ts" setup>
</script>

<template>
    <div>Index Page for Webapp</div>
</template>

@formatter:off
<route lang="yaml">
redirect: /dashboard
</route>
