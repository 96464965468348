import type { ResourceFile } from '@/models/api/resource/ResourceListResponse'

export class APIParseResponseError extends Error {
    /** Status code of api response. It's undefined, if the error occurred before or after handling the response, so that there is no status available.*/
    statusCode: number | undefined

    constructor(message: string, statusCode: number, cause?: Error) {
        super(message)
        this.name = 'APIParseResponseError'
        this.statusCode = statusCode
        this.cause = cause
    }
}

export type OnSuccess<dataType = void> = (data: dataType) => void
export type OnRessourceIndexSuccess = (basePath: string, items: ResourceFile[]) => void

export type OnError = (error: APIParseResponseError) => void;
